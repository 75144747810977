"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.divShadowClass = exports.textHighlightCurrentDayClass = exports.badgeDoctorIsOfflineClass = exports.badgeDoctorIsOnlineClass = exports.doctorsOnlineStatusBadgeId = exports.doctorsOnlineTextSpanId = exports.tableDivId = exports.btnLinkToInstanceId = exports.errorDivId = exports.contentDivId = exports.wrapperId = void 0;
/**
 * ID of the Wrapper-Element
 */
exports.wrapperId = "zs-arzt-direkt-timetable";
/**
 * ID of the div with the content
 */
exports.contentDivId = "zs-tt--div-content";
/**
 * ID of the div providing error messages
 */
exports.errorDivId = "zs-tt--div-error";
/**
 * ID of the button to link to the instance site
 */
exports.btnLinkToInstanceId = "zs-tt--btn-link-to-instance-site";
/**
 * ID of the planned availability table.
 */
exports.tableDivId = "zs-tt--div-planned-availability-table";
/**
 * ID of the text span with the doctors online data description
 */
exports.doctorsOnlineTextSpanId = "zs-tt--span-doctors-online-text";
/**
 * ID of the badge which signals the doctors online status
 */
exports.doctorsOnlineStatusBadgeId = "zs-tt--badge-doctors-online-status";
exports.badgeDoctorIsOnlineClass = "zs-tt--badge--doctor-is-online";
exports.badgeDoctorIsOfflineClass = "zs-tt--badge--doctor-is-offline";
exports.textHighlightCurrentDayClass = "zs-tt--text--highlight-current-day";
exports.divShadowClass = "zs-tt--div--shadow";
