"use strict";
//---------------------------------------------------------------
//----- see https://fettblog.eu/jsx-syntactic-sugar/
//----------------------------------------------------------------
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsxFactory = void 0;
function parseChild(child) {
    if (typeof child === 'string') {
        return document.createTextNode(child);
    }
    else {
        return child;
    }
}
function parseNode(element, props, children) {
    var el = document.createElement(element);
    Object.keys(props !== null && props !== void 0 ? props : {}).forEach(function (key) {
        el[key] = props[key];
    });
    for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
        var child = children_1[_i];
        el.appendChild(parseChild(child));
    }
    return el;
}
function jsxFactory(element, props) {
    var children = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        children[_i - 2] = arguments[_i];
    }
    if (typeof element === 'function') {
        return element(__assign(__assign({}, (props !== null && props !== void 0 ? props : {})), { children: children }));
    }
    return parseNode(element, props, children);
}
exports.jsxFactory = jsxFactory;
