"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templateDiv = void 0;
var jsxFactory_1 = require("../jsxFactory");
var ids_1 = require("./ids");
exports.templateDiv = ((0, jsxFactory_1.jsxFactory)("div", null,
    (0, jsxFactory_1.jsxFactory)("div", { id: ids_1.errorDivId, className: "zs-hidden", role: "alert" }),
    (0, jsxFactory_1.jsxFactory)("div", { id: ids_1.contentDivId },
        (0, jsxFactory_1.jsxFactory)("div", { className: "zs-flex zs-flex-nowrap zs-flex-col zs-gap-4" },
            (0, jsxFactory_1.jsxFactory)("div", { className: "zs-min-w-full zs-flex zs-flex-row zs-flex-wrap zs-place-content-start zs-gap-4" },
                (0, jsxFactory_1.jsxFactory)("div", { className: "zs-flex zs-flex-row zs-items-center zs-gap-1" },
                    (0, jsxFactory_1.jsxFactory)("span", { id: ids_1.doctorsOnlineStatusBadgeId, className: ids_1.badgeDoctorIsOfflineClass }, " "),
                    (0, jsxFactory_1.jsxFactory)("span", { id: ids_1.doctorsOnlineTextSpanId })),
                (0, jsxFactory_1.jsxFactory)("button", { id: ids_1.btnLinkToInstanceId, className: "zs-hidden" }, "Zur Videosprechstunde")),
            (0, jsxFactory_1.jsxFactory)("div", { className: "zs-min-w-full zs-mb-1 zs-align-middle zs-inline-block" },
                (0, jsxFactory_1.jsxFactory)("div", { className: "zs-overflow-hidden ".concat(ids_1.divShadowClass) },
                    (0, jsxFactory_1.jsxFactory)("div", { id: ids_1.tableDivId })))))));
