"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./index.css");
var ids_1 = require("./ids");
var template_1 = require("./template");
/**
 * fetch interval for doctors-online data in ms
 */
var doctorsOnlineFetchInterval = 10000;
var doctorsOnlineUpdateTimer;
/**
 * breakpoint for availability table in px
 */
var breakpointAvailabilityTable = 768; // this is tailwinds `md`
var errorMsgNoResponse = "Bei der Kommunikation mit arzt-direkt ist ein Fehler aufgetreten: " +
    "Versuchen Sie die Seite zu aktualisieren, sollte der Fehler bestehen, kontaktieren Sie " +
    "bitte die Praxis bzw. den Webmaster.";
var errorMsgNoIdentifier = "Das Modul wurde fehlerhaft eingebunden (Praxis-Kennung fehlt): " +
    "Kontaktieren Sie bitte die Praxis bzw. den Webmaster.";
var errorMsgNoWrapperDiv = "Das Modul wurde fehlerhaft eingebunden, es fehlt ein " +
    "gekennzeichnetes HTML-Element zum Einfügen der Sprechzeiten-Tabelle (sollte " +
    "id=\"".concat(ids_1.wrapperId, "\" haben).");
/**
 * IDs for the weekdays.
 *
 * IDs to access the planned availability data
 * for a specific weekday. this list is in order,
 * starting at monday.
 */
var weekDayIds = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];
function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }
    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
}
function getBaseUrl() {
    if (window.location.hostname == "localhost" && window.location.port == "7070") {
        return "http://localhost:6060";
    }
    else if (window.location.hostname == "localhost" && window.location.port == "6060") {
        return "";
    }
    else if (["test-arzt-direkt.zollsoft.net", "arzt-direkt.com", "app.arzt-direkt.de"].includes(window.location.hostname)) {
        return "";
    }
    else {
        var script = document.currentScript || document.querySelector('script[src*="static/timetable/index.js"]');
        var domain = extractHostname(script === null || script === void 0 ? void 0 : script.src);
        return "//".concat(domain);
    }
}
function getInstanceSiteUrl(instanceId) {
    return getBaseUrl() + "/" + instanceId;
}
function getApiBaseUrl() {
    return getBaseUrl() + "/api/landing";
}
function getFetchUrlApiInstance(instanceId) {
    return getApiBaseUrl() + "/" + instanceId;
}
function getFetchUrlPlannedAvailability(instanceId) {
    return getFetchUrlApiInstance(instanceId) + "/planned-availability";
}
function getFetchUrlOnlineDoctors(instanceId) {
    return getFetchUrlApiInstance(instanceId) + "/online-doctors";
}
/**
 * fetches online doctors data.
 * @param instanceId id of the instance
 */
function fetchOnlineDoctors(instanceId) {
    return new Promise(function (resolve, reject) {
        var url = getFetchUrlOnlineDoctors(instanceId);
        fetch(url, { method: "GET" })
            .then(function (response) { return response.json(); })
            .then(function (response) {
            var onlineDoctors = response["onlineDoctors"] || {};
            console.log("Fetched online-doctors:", onlineDoctors);
            resolve(onlineDoctors);
        })
            .catch(function (error) {
            console.error("Error while fetching online-doctors:", error);
            reject(error);
        });
    });
}
/**
 * fetches planned availability data.
 * @param instanceId id of the instance
 */
function fetchPlannedAvailability(instanceId) {
    return new Promise(function (resolve, reject) {
        var url = getFetchUrlPlannedAvailability(instanceId);
        fetch(url, { method: "GET" })
            .then(function (response) { return response.json(); })
            .then(function (response) {
            var plannedAvailability = response["plannedAvailability"] || {};
            console.log("Fetched planned-availability:", plannedAvailability);
            resolve(plannedAvailability);
        })
            .catch(function (error) {
            console.error("Error while fetching planned-availability:", error);
            reject(error);
        });
    });
}
// ------- render
/**
 * renders the timetable DOM.
 */
function renderTimetableDOM() {
    var wrapperEl = document.getElementById(ids_1.wrapperId);
    wrapperEl === null || wrapperEl === void 0 ? void 0 : wrapperEl.insertAdjacentHTML('beforeend', template_1.templateDiv.innerHTML);
}
/**
 * renders the planned-availability table.
 *
 * renders the given planned-availability data into the
 * designated html elements.
 * @param data planned-availability data
 */
function renderPlannedAvailabilityTable(data) {
    // no data, do nothing
    if (!data) {
        console.error("Tried to render planned Availability table without valid data. data was:", data);
        return;
    }
    var tableDiv = document.getElementById(ids_1.tableDivId);
    // whether to render in portrait mode (header in a column)
    var portrait = document.documentElement.clientWidth < breakpointAvailabilityTable;
    // fallback for no date
    var noDateText = "⁄";
    // create table
    var table = document.createElement("table");
    var dayLabels = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
    var currentDay = ((new Date()).getDay() + 6) % 7;
    if (portrait) {
        // portrait mode
        // render each day in a row
        var body_1 = table.createTBody();
        weekDayIds.forEach(function (weekDayId, idx) {
            var _a;
            var row = body_1.insertRow();
            var dayLabel = dayLabels[idx];
            // day label
            var th = document.createElement("th");
            var thText = document.createTextNode(dayLabel);
            th.appendChild(thText);
            row.appendChild(th);
            // day dates
            var td = document.createElement("td");
            var dateLabel = (_a = data[weekDayId]) !== null && _a !== void 0 ? _a : "";
            var tdText = document.createTextNode(dateLabel != "" ? dateLabel : noDateText);
            td.appendChild(tdText);
            row.appendChild(td);
            // highlight current day
            if (idx == currentDay) {
                th.classList.add(ids_1.textHighlightCurrentDayClass);
                td.classList.add(ids_1.textHighlightCurrentDayClass);
            }
        });
    }
    else {
        // landscape mode
        // render table header
        var head = table.createTHead();
        var headRow_1 = head.insertRow();
        dayLabels.forEach(function (dayLabel, idx) {
            var th = document.createElement("th");
            var text = document.createTextNode(dayLabel);
            th.appendChild(text);
            headRow_1.appendChild(th);
            // highlight current day
            if (idx == currentDay) {
                th.classList.add(ids_1.textHighlightCurrentDayClass);
            }
        });
        // render table body
        var body = table.createTBody();
        var bodyRow_1 = body.insertRow();
        weekDayIds.forEach(function (weekDayId, idx) {
            var _a;
            var td = document.createElement("td");
            var dateLabel = (_a = data[weekDayId]) !== null && _a !== void 0 ? _a : "";
            var text = document.createTextNode(dateLabel != "" ? dateLabel : noDateText);
            td.appendChild(text);
            bodyRow_1.appendChild(td);
            // highlight current day
            if (idx == currentDay) {
                td.classList.add(ids_1.textHighlightCurrentDayClass);
            }
        });
    }
    // assign table
    tableDiv.innerHTML = "";
    tableDiv.appendChild(table);
}
/**
 * renders the text and status badge for the doctors-online data.
 *
 * renders the given doctors-online data into the
 * designated html elements.
 * @param data doctors-online data
 */
function renderDoctorsOnlineStatusBadge(data) {
    var _a;
    if (!data) {
        console.error("Tried to render planned doctors online status badge without valid data. data was:", data);
        return;
    }
    var textSpan = document.getElementById(ids_1.doctorsOnlineTextSpanId);
    var instanceButton = document.getElementById(ids_1.btnLinkToInstanceId);
    var badge = document.getElementById(ids_1.doctorsOnlineStatusBadgeId);
    if (!data) {
        badge.classList.remove(ids_1.badgeDoctorIsOnlineClass);
        badge.classList.add(ids_1.badgeDoctorIsOfflineClass);
        instanceButton.classList.add('zs-hidden');
        textSpan.innerText = "";
        return;
    }
    if (((_a = data === null || data === void 0 ? void 0 : data.sum) !== null && _a !== void 0 ? _a : 0) > 0) {
        badge.classList.remove(ids_1.badgeDoctorIsOfflineClass);
        badge.classList.add(ids_1.badgeDoctorIsOnlineClass);
        instanceButton.classList.remove('zs-hidden');
        textSpan.innerText = data.sum < 2 ? "1 Arzt online" : "".concat(data.sum, " \u00C4rzte online");
    }
    else {
        badge.classList.remove(ids_1.badgeDoctorIsOnlineClass);
        badge.classList.add(ids_1.badgeDoctorIsOfflineClass);
        instanceButton.classList.add('zs-hidden');
        textSpan.innerText = "Kein Arzt online";
    }
}
function renderNoIdentifier() {
    var contentDiv = document.getElementById(ids_1.contentDivId);
    var errorDiv = document.getElementById(ids_1.errorDivId);
    errorDiv.innerText = errorMsgNoIdentifier;
    contentDiv.classList.add("zs-hidden");
    errorDiv.classList.remove("zs-hidden");
}
function renderFetchError() {
    var contentDiv = document.getElementById(ids_1.contentDivId);
    var errorDiv = document.getElementById(ids_1.errorDivId);
    errorDiv.innerText = errorMsgNoResponse;
    contentDiv.classList.add("zs-hidden");
    errorDiv.classList.remove("zs-hidden");
}
// ------ events
function linkToArztDirektInstance() {
    if (instanceId) {
        window.open(getInstanceSiteUrl(instanceId));
    }
}
// ------ helpers
var onlineDoctorsData, plannedAvailabilityData;
function updateAndRenderDoctorsOnline() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchOnlineDoctors(instanceId).catch(function (_) { return null; })];
                case 1:
                    onlineDoctorsData = _a.sent();
                    if (!onlineDoctorsData) {
                        processFetchError();
                        return [2 /*return*/];
                    }
                    renderDoctorsOnlineStatusBadge(onlineDoctorsData);
                    return [2 /*return*/];
            }
        });
    });
}
function updateAndRenderPlannedAvailability() {
    return __awaiter(this, arguments, void 0, function (portrait) {
        if (portrait === void 0) { portrait = false; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchPlannedAvailability(instanceId).catch(function (_) { return null; })];
                case 1:
                    plannedAvailabilityData = _a.sent();
                    if (!plannedAvailabilityData) {
                        processFetchError();
                        return [2 /*return*/];
                    }
                    renderPlannedAvailabilityTable(plannedAvailabilityData === null || plannedAvailabilityData === void 0 ? void 0 : plannedAvailabilityData.sum);
                    return [2 /*return*/];
            }
        });
    });
}
function processFetchError() {
    fetchError = true;
    if (doctorsOnlineUpdateTimer) {
        clearInterval(doctorsOnlineUpdateTimer);
    }
    renderFetchError();
    return;
}
// ------- main
// // evaluate url params
// const urlParams = new URLSearchParams(window.location.search);
var instanceId = "";
var fetchError = false;
var noIdentifier = true;
document.addEventListener("DOMContentLoaded", function () {
    var _a;
    var wrapperEl = document.getElementById(ids_1.wrapperId);
    if (!wrapperEl) {
        console.error(errorMsgNoWrapperDiv);
        return;
    }
    instanceId = (_a = wrapperEl === null || wrapperEl === void 0 ? void 0 : wrapperEl.dataset) === null || _a === void 0 ? void 0 : _a.instance;
    noIdentifier = !instanceId || (instanceId == "");
    // render DOM within wrapper
    renderTimetableDOM();
    // no identifier
    if (noIdentifier) {
        renderNoIdentifier();
        return;
    }
    // initial render
    updateAndRenderPlannedAvailability();
    updateAndRenderDoctorsOnline();
    // start timer for procedural render
    doctorsOnlineUpdateTimer = setInterval(updateAndRenderDoctorsOnline, doctorsOnlineFetchInterval);
    // listeners
    document.getElementById(ids_1.btnLinkToInstanceId).addEventListener("click", function () {
        linkToArztDirektInstance();
    });
});
// responsive layout
// screen size (last values)
var lastWidth = document.documentElement.clientWidth;
window.addEventListener("resize", function () {
    var newWidth = document.documentElement.clientWidth;
    var newBelow = newWidth < breakpointAvailabilityTable;
    var lastBelow = lastWidth < breakpointAvailabilityTable;
    if ((plannedAvailabilityData === null || plannedAvailabilityData === void 0 ? void 0 : plannedAvailabilityData.sum) && ((newBelow && !lastBelow) || (!newBelow && lastBelow))) {
        renderPlannedAvailabilityTable(plannedAvailabilityData.sum);
    }
    lastWidth = newWidth;
});
